import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Icon from 'shopper/components/Icon';
import Price from 'shopper/components/Price';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';
import OfferCardTags from 'components/Timeline/OfferCard/Tags';

import { getOfferImageLink, toOfferImageAltText } from 'lib/image';
import { toOfferLink } from 'lib/links';
import { toFormattedOfferOldPrice, toFormattedOfferPrice } from 'lib/offer';
import { noop } from 'lib/utils';

import Footer from './Footer';

import OFFER from 'constants/offer';

const OfferRecommendedCard = ({
  authors,
  cardTitleAs = 'span',
  gaEventCategory,
  likesCount,
  offerComments,
  offerId,
  offerImagePriority = false,
  offerOldPrice = null,
  offerPhoto,
  offerPrice,
  offerPriceType,
  offerPublished,
  offerSlug,
  offerStatusName,
  offerTags = [],
  offerTitle,
  showFilterCategoriesDropdown = false,
  showFooter,
  storeDomain,
  storeName,
  userId,
  userName,
  onImageClick = noop,
  onTitleClick = noop,
}) => (
  <>
    <NextLink href={toOfferLink(offerSlug)} prefetch={false} passHref>
      <Anchor className="flex h-full flex-col justify-between rounded-2 bg-neutral-high-100 px-2 dark:bg-neutral-low-500">
        <div className="flex size-full pb-3 pt-2 md:pt-2">
          <div className="relative flex h-[118px] justify-center rounded-2">
            <Image
              alt={toOfferImageAltText(offerTitle)}
              className={twJoin(
                'rounded-2',
                offerStatusName === OFFER.STATUSES.FINISHED && 'opacity-30'
              )}
              grayscale={offerStatusName === OFFER.STATUSES.FINISHED}
              height={120}
              loading={offerImagePriority ? null : 'lazy'}
              priority={offerImagePriority}
              src={getOfferImageLink(offerPhoto, 120)}
              srcSet={`
                ${getOfferImageLink(offerPhoto, 180)} 180w,
                ${getOfferImageLink(offerPhoto, 240)} 240w
              `}
              width={120}
              onClick={onImageClick}
            />
          </div>
          <div className="flex w-full flex-col flex-wrap overflow-hidden pl-2 md:flex-row md:pl-4">
            <div className="flex size-full flex-col md:flex-3 lg:flex-4">
              <Box className="relative size-full items-stretch">
                <Text
                  as={cardTitleAs}
                  className="line-clamp-2 max-w-[95%] break-words text-neutral-low-100 dark:text-neutral-high-100"
                  size="size2"
                  onClick={onTitleClick}
                >
                  {offerTitle}
                </Text>
              </Box>
              <div className="flex">
                <OfferCardTags
                  className="order-1 mt-2 md:order-unset md:flex"
                  offerTags={offerTags}
                  limitOffersTagsByOfferCardSize
                />
              </div>
              <div
                className={twJoin(
                  'flex w-full flex-col bg-neutral-high-100 dark:bg-neutral-low-500',
                  showFilterCategoriesDropdown
                    ? 'justify-between'
                    : 'justify-center'
                )}
              >
                <Box>
                  <Price
                    className="mt-1 md:mt-0"
                    finished={offerStatusName === OFFER.STATUSES.FINISHED}
                    primaryInfo={toFormattedOfferPrice(
                      offerPrice,
                      offerPriceType
                    )}
                    secondaryInfo={toFormattedOfferOldPrice(
                      offerOldPrice,
                      offerPriceType
                    )}
                    size="size3"
                  />
                  <div className="mt-1 flex justify-between">
                    <Text
                      as="span"
                      className="truncate whitespace-nowrap"
                      size="size2"
                    >
                      {storeName || storeDomain}
                    </Text>
                    <div className="ml-2 flex items-center">
                      <Icon
                        className="text-neutral-low-100 dark:text-neutral-high-200"
                        name="messages"
                        size="size3"
                      />
                      <Text
                        as="span"
                        className="ml-1 text-neutral-low-100 dark:text-neutral-high-100"
                        size="size2"
                        bold
                      >
                        {offerComments}
                      </Text>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
        {showFooter && (
          <Footer
            authors={authors}
            gaEventCategory={gaEventCategory}
            likesCount={likesCount}
            offerId={offerId}
            offerPublished={offerPublished}
            userId={userId}
            userName={userName}
          />
        )}
      </Anchor>
    </NextLink>
  </>
);

OfferRecommendedCard.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  cardTitleAs: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  offerComments: PropTypes.number.isRequired,
  offerId: PropTypes.number.isRequired,
  offerImagePriority: PropTypes.bool,
  offerOldPrice: PropTypes.number,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerPriceType: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  offerTitle: PropTypes.string.isRequired,
  showFilterCategoriesDropdown: PropTypes.bool,
  showFooter: PropTypes.bool.isRequired,
  storeDomain: PropTypes.string.isRequired,
  subcategoryId: PropTypes.number,
  onImageClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

export default memo(OfferRecommendedCard);
