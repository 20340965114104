// Params are options from the Intl.NumberFormat() constructor
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export const formatAsCurrency = ({
  currency = 'BRL',
  currencyDisplay,
  number = 0,
  locale = 'pt-BR',
}) =>
  new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay,
    style: 'currency',
  }).format(number);

export const convertBrlCurrencyToNumber = (num) =>
  Number(num.replace('.', '').replace(',', '.'));

export const isFloat = (number) => !!(number % 1);

export const isInteger = (number) => Number.isInteger(number);

export const isNumber = (number) => isFloat(number) || isInteger(number);
