import PropTypes from 'prop-types';
import { memo } from 'react';

import Text from 'shopper/components/Text';

import LikeButton from 'components/OfferLikes/LikeButton';

import { getShortenedTimeTo } from 'lib/date';

import Authors from '../../../Authors';

const OfferRecommendedCardFooter = ({
  authors,
  gaEventCategory,
  offerId,
  offerPublished,
  likesCount,
  userId,
  userName,
}) => (
  <div className="flex w-full items-center justify-between border-t border-neutral-high-200 py-2 dark:border-neutral-low-300">
    <div className="grid grid-cols-[repeat(2,auto)] grid-rows-[repeat(2,auto)] items-center gap-x-2">
      <Authors
        authors={authors}
        className="col-start-1 row-span-2 row-start-1"
        firstAuthorId={userId}
      />
      <Text
        className="line-clamp-1 truncate text-neutral-low-100 dark:text-neutral-high-400"
        size="size3"
        bold
      >
        {userName}
      </Text>
      <Text
        className="truncate whitespace-nowrap text-neutral-low-100 dark:text-neutral-high-400"
        size="size3"
        suppressHydrationWarning
      >
        {getShortenedTimeTo(offerPublished)}
      </Text>
    </div>
    <LikeButton
      gaEventCategory={gaEventCategory}
      likesCount={likesCount}
      offerId={offerId}
      size="size4"
    />
  </div>
);

OfferRecommendedCardFooter.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  offerId: PropTypes.number.isRequired,
  offerPublished: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

export default memo(OfferRecommendedCardFooter);
